<template>
  <div>
    <div
      v-if="isChangeOrder && (!action || action !== 'ADD') && currentSelectedBandwidthText"
      class="margin__bottom__24"
    >
      <Summary :editable="false">
        <telia-p>
          <strong>{{ t("DATANET_INTERNET_ON_SITE_CURRENT_CONFIGURATION") }}</strong>
          <br />
          <span>
            {{ `${t("DATANET_INTERNET_ON_SITE_CAPACITY")}: ${currentSelectedBandwidthText}` }}
          </span>
        </telia-p>
      </Summary>
    </div>
    <Summary
      v-if="action === 'ADD' || action === 'DELETE'"
      class="margin__bottom__24"
      :edit-label="t('DATANET_EDIT')"
      @edit="showInternetOnSiteConfigModal()"
    >
      <telia-p>
        <strong>{{ t("DATANET_INTERNET_ON_SITE") }}</strong>
        <br />
        <span v-if="action === 'ADD'">
          {{
            `${t("DATANET_INTERNET_ON_SITE_IP_ADDRESSES")}: ${
              ripeNumber
                ? t("DATANET_INTERNET_ON_SITE_NEW_IP")
                : t("DATANET_INTERNET_ON_SITE_EXISTING_IP")
            }`
          }}
          <br />
        </span>
        <span v-if="action === 'ADD'">
          {{ `${t("DATANET_INTERNET_ON_SITE_INTERFACE")}: ${lanInterface}` }}
          <br />
        </span>
        <span v-if="action === 'ADD'">
          {{
            `${t("DATANET_INTERNET_ON_SITE_CAPACITY")}: ${
              useDynamicCapacity
                ? t("DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY")
                : t("DATANET_INTERNET_ON_SITE_STATIC_BANDWIDTH")
            }`
          }}
          <br />
        </span>
        <span v-if="action === 'ADD' && !useDynamicCapacity">
          {{ `${t("DATANET_INTERNET_ON_SITE_SELECTED_BANDWIDTH")}: ${getStaticBandwidthText()}` }}
          <br />
        </span>
        <span v-if="action === 'ADD'">
          {{
            `${t("DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES")}: ${
              extraEthernetInterfaces > 0
                ? t("DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES", {
                    number: extraEthernetInterfaces,
                  })
                : t("DATANET_INTERNET_ON_SITE_NO_EXTRA_ETHERNET_INTERFACES")
            }`
          }}
          <br />
        </span>
        <span v-if="action === 'DELETE'">
          {{ t("DATANET_INTERNET_ON_SITE_REMOVE") }}
          <br />
        </span>
      </telia-p>
    </Summary>

    <SummaryList v-if="action === 'UPDATE'" class="change-summary">
      <SummaryRow variant="header">
        <SummaryColumn>
          <telia-p>{{ t("DATANET_CHANGE") }}</telia-p>
        </SummaryColumn>
        <SummaryColumn> &nbsp; </SummaryColumn>
        <SummaryColumn>
          <telia-p>{{ t("DATANET_CURRENT_VALUE") }}</telia-p>
        </SummaryColumn>
        <SummaryColumn>
          <telia-p>{{ t("DATANET_NEW_VALUE") }}</telia-p>
        </SummaryColumn>
        <SummaryColumn variant="small"> &nbsp; </SummaryColumn>
      </SummaryRow>
      <SummaryRow v-if="isCapacityChanged">
        <SummaryColumn variant="align-top">
          <telia-p> {{ t(`DATANET_ACTION_${action}`) }}</telia-p>
        </SummaryColumn>
        <SummaryColumn variant="align-top">
          <div class="field-title">
            <telia-p>{{ t("DATANET_INTERNET_ON_SITE_SELECT_BANDWIDTH") }}</telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn variant="align-top">
          <div>
            <telia-p>{{ currentSelectedBandwidthText || "-" }}</telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn>
          <div>
            <telia-p>{{ newSelectedBandwidthText || "-" }}</telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn variant="small" class="align-end">
          <div
            id="edit-internet-on-site-bandwidth-change"
            class="summary-link"
            @click="showInternetOnSiteConfigModal()"
          >
            <i class="link-icon"><telia-icon name="edit" size="sm" /></i>
          </div>
        </SummaryColumn>
      </SummaryRow>
      <SummaryRow v-if="isEthernetInterfacesChanged">
        <SummaryColumn variant="align-top">
          <telia-p> {{ t(`DATANET_ACTION_${action}`) }}</telia-p>
        </SummaryColumn>
        <SummaryColumn variant="align-top">
          <div class="field-title">
            <telia-p> {{ t("DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES") }}</telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn variant="align-top">
          <div>
            <telia-p>
              {{
                `${
                  currentExtraEthernetInterfaces > 0
                    ? t("DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES", {
                        number: currentExtraEthernetInterfaces,
                      })
                    : "-"
                }`
              }}
            </telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn>
          <div>
            <telia-p>
              {{
                `${
                  extraEthernetInterfaces > 0
                    ? t("DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES", {
                        number: extraEthernetInterfaces,
                      })
                    : t("DATANET_INTERNET_ON_SITE_NO_EXTRA_ETHERNET_INTERFACES")
                }`
              }}
            </telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn variant="small" class="align-end">
          <div
            id="edit-internet-on-site-interface-change"
            class="summary-link"
            @click="showInternetOnSiteConfigModal()"
          >
            <i class="link-icon"><telia-icon name="edit" size="sm" /></i>
          </div>
        </SummaryColumn>
      </SummaryRow>
    </SummaryList>

    <Modal
      v-if="modalIsActive"
      modal-id="internet-on-site-config-modal"
      width="m"
      :is-open="modalIsActive"
      @closeModal="hideInternetOnSiteConfigModal()"
    >
      <div slot="modal-body">
        <InternetOnSiteConfig @complete="hideInternetOnSiteConfigModal()" />
      </div>
    </Modal>

    <div class="margin__top__12">
      <telia-button
        v-if="action"
        id="remove-internet-on-site-config"
        t-id="remove-internet-on-site-config"
        type="button"
        variant="secondary"
        size="sm"
        :left-icon="JSON.stringify({ name: 'close', size: 'sm' })"
        :text="
          isChangeOrder
            ? t('DATANET_INTERNET_ON_SITE_REMOVE_CHANGE')
            : t('DATANET_INTERNET_ON_SITE_REMOVE')
        "
        :disabled="!isPrimaryAccessInternetOnSiteEditable"
        @click="resetInternetOnSite()"
      />
      <telia-button
        v-else
        id="add-internet-on-site-config"
        t-id="add-internet-on-site-config"
        type="button"
        variant="secondary"
        size="sm"
        :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
        :text="
          isChangeOrder
            ? t('DATANET_INTERNET_ON_SITE_ADD_CHANGE')
            : t('DATANET_INTERNET_ON_SITE_ADD')
        "
        :disabled="!isPrimaryAccessInternetOnSiteEditable"
        @click="showInternetOnSiteConfigModal()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { translateMixin } from "../../locale";
import { Accesses, Actions } from "../../constants";
import createBandwidthLabel from "../../helpers/createBandwidthLabel";
import InternetOnSiteConfig from "./InternetOnSiteConfig.vue";
import Modal from "../Modal.vue";
import Summary from "../Summary.vue";
import SummaryRow from "../summary/SummaryRow.vue";
import SummaryColumn from "../summary/SummaryColumn.vue";
import SummaryList from "../summary/SummaryList.vue";

export default {
  name: "InternetOnSite",
  components: {
    SummaryList,
    SummaryColumn,
    SummaryRow,
    InternetOnSiteConfig,
    Modal,
    Summary,
  },
  mixins: [translateMixin],

  data() {
    return {
      modalIsActive: false,
    };
  },

  computed: {
    ...mapState("access", {
      action: (state) => state[Accesses.PRIMARY].options.internetOnSite.action,
      ripeNumber: (state) => state[Accesses.PRIMARY].options.internetOnSite.ripeNumber,
      lanInterface: (state) => state[Accesses.PRIMARY].options.internetOnSite.lanInterface,
      useDynamicCapacityAction: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.dynamic.action,
      currentUseDynamicCapacity: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.dynamic.current,
      useDynamicCapacity: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.dynamic.new,
      currentStaticBandwidth: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.bandwidth.current,
      staticBandwidthAction: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.bandwidth.action,
      staticBandwidth: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.bandwidth.new,
      extraEthernetInterfacesAction: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.ethernetInterface.action,
      currentExtraEthernetInterfaces: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.ethernetInterface.current,
      extraEthernetInterfaces: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.ethernetInterface.new,
    }),
    ...mapGetters(["isChangeOrder"]),
    ...mapGetters("access", ["isPrimaryAccessInternetOnSiteEditable"]),

    isCapacityChanged() {
      return (
        this.currentUseDynamicCapacity !== this.useDynamicCapacity ||
        this.currentStaticBandwidth !== this.staticBandwidth
      );
    },

    isEthernetInterfacesChanged() {
      return this.extraEthernetInterfacesAction === Actions.UPDATE;
    },

    currentSelectedBandwidthText() {
      if (this.currentUseDynamicCapacity) {
        return this.t("DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY");
      } else if (this.currentStaticBandwidth) {
        return this.getCurrentStaticBandwidthText();
      }

      return null;
    },

    newSelectedBandwidthText() {
      if (this.useDynamicCapacity) {
        return this.t("DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY");
      } else if (this.staticBandwidth) {
        return this.getStaticBandwidthText();
      }

      return null;
    },
  },

  methods: {
    ...mapActions("access", [
      "setInternetOnSiteAction",
      "setInternetOnSiteUseDynamicCapacity",
      "setInternetOnSiteStaticBandwidth",
      "setInternetOnSiteExtraEthernetInterfaces",
    ]),

    showInternetOnSiteConfigModal() {
      this.modalIsActive = true;
    },

    hideInternetOnSiteConfigModal() {
      this.modalIsActive = false;
    },

    getCurrentStaticBandwidthText() {
      const bandwidth = Number(this.currentStaticBandwidth);
      return createBandwidthLabel(bandwidth, bandwidth);
    },

    getStaticBandwidthText() {
      const bandwidth = Number(this.staticBandwidth);
      return createBandwidthLabel(bandwidth, bandwidth);
    },

    resetInternetOnSite() {
      this.setInternetOnSiteAction({
        accessKey: Accesses.PRIMARY,
        action: null,
      });

      this.setInternetOnSiteUseDynamicCapacity({
        accessKey: Accesses.PRIMARY,
        useDynamicCapacity: {
          action: null,
          current: this.currentUseDynamicCapacity,
          new: null,
        },
      });

      this.setInternetOnSiteStaticBandwidth({
        accessKey: Accesses.PRIMARY,
        staticBandwidth: {
          action: null,
          current: this.currentStaticBandwidth,
          new: null,
        },
      });

      this.setInternetOnSiteExtraEthernetInterfaces({
        accessKey: Accesses.PRIMARY,
        extraEthernetInterfaces: {
          action: null,
          current: this.currentExtraEthernetInterfaces,
          new: 0,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.change-summary {
  max-width: 80rem;
}

.summary-link {
  float: right;
  margin-top: $telia-spacing-4;
  cursor: pointer;
  transition: all 150ms ease;
}

.summary-link:hover {
  color: $telia-purple-500;
}

.summary-link:active {
  color: $telia-purple-500;
}

.field-title {
  color: $telia-gray-500;
}

.margin {
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
  &__bottom {
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
