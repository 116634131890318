<template>
  <div>
    <Organisation @selected="handleOrganisationSelection($event)" />

    <telia-notification
      v-if="alert.show"
      t-id="organisation-alert"
      heading-tag="h4"
      variant="inline"
      :status="alert.status"
      html-aria-live="polite"
      html-role="alert"
    >
      <span slot="heading">
        <telia-visually-hidden>
          {{ t("A11Y_STATUS_WARNING") }}
        </telia-visually-hidden>
        {{ alert.title }}
      </span>
      <span slot="content">
        <telia-p>
          {{ alert.text }}
        </telia-p>
      </span>
    </telia-notification>
    <div class="step-button-wrapper">
      <telia-button
        t-id="next-step"
        type="button"
        variant="primary"
        size="sm"
        :disabled="disableNextStep"
        @click="completeStep()"
      >
        {{ buttonText }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { translateMixin } from "../locale";
import { corpOrderService } from "@telia/b2b-rest-client";
import { isTestEnv } from "@telia/b2b-utils";
import Organisation from "./Organisation";

export default {
  name: "OrganisationSettings",

  mixins: [translateMixin],

  components: {
    Organisation,
  },

  props: {
    scopeId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      disableNextStep: true,
      buttonText: this.t("DATANET_NEXT_STEP"),
      sdwLoginRequired: false,
      hasSdwOrderAccess: false,
      alert: {
        show: false,
        title: "",
        text: "",
        variant: "warning",
      },
    };
  },

  computed: {
    ...mapState("user", {
      organisation: (state) => state.organisation,
    }),

    ...mapGetters("user", ["getOrganisation"]),
  },

  methods: {
    async handleOrganisationSelection(organisationNumber) {
      this.hidePageAlert();
      this.disableNextStep = true;
      this.sdwLoginRequired = false;
      this.hasSdwOrderAccess = false;

      if (!organisationNumber) {
        return;
      }

      try {
        this.buttonText = this.t("CHECKING_ORGANISATION");
        const organisation = this.getOrganisation(organisationNumber);
        const tscid = organisation.tscid;
        const dataNetPermissions = await this.checkOrganisationDataNetOrderPermission(tscid);

        if (dataNetPermissions.indexOf("DATANET_PB") !== -1) {
          this.buttonText = this.t("DATANET_NEXT_STEP");
          this.disableNextStep = false;
        } else if (dataNetPermissions.indexOf("DATANET_FB") !== -1) {
          const {
            outcome,
            permissionList,
          } = await corpOrderService.SdwOrderPermissionsControllerService.getSdwUserPermissions(
            this.scopeId
          );

          if (outcome === "SDW_LOGIN_REQUIRED") {
            this.sdwLoginRequired = true;
          } else if (outcome === "OK") {
            if (permissionList.indexOf("ORDER_NEW_SUBSCRIPTION") !== -1) {
              this.hasSdwOrderAccess = true;
            }
          }

          this.showPageAlert(
            this.t("CONTINUE_ORDER_IN_SDW_TITLE"),
            this.t("CONTINUE_ORDER_IN_SDW"),
            "information"
          );
          this.buttonText = this.t("GO_TO_SDW");
          this.disableNextStep = false;
        } else {
          this.showPageAlert(
            this.t("ORGANISATION_ERROR_TITLE"),
            this.t("ORGANISATION_ERROR"),
            "warning"
          );
          this.buttonText = this.t("DATANET_NEXT_STEP");
        }
      } catch (e) {
        this.showPageAlert(
          this.t("ORGANISATION_ERROR_TITLE"),
          this.t("ORGANISATION_ERROR"),
          "warning"
        );
        this.buttonText = this.t("DATANET_NEXT_STEP");
      }
    },

    async checkOrganisationDataNetOrderPermission(tscId) {
      try {
        const {
          permissionList,
        } = await corpOrderService.DataComOrderPermissionsControllerService.getDataComUserPermissionsOnTscId(
          this.scopeId,
          { tscId }
        );
        return permissionList;
      } catch (e) {
        return [];
      }
    },

    async checkDataNetOrderPermission() {
      try {
        const permissionData = await corpOrderService.DataComOrderPermissionsControllerService.getDataComUserPermissions(
          this.scopeId
        );
        return permissionData.permissionList.indexOf("DATANET") !== -1;
      } catch (e) {
        return false;
      }
    },

    showPageAlert(title, text, status) {
      this.alert.title = title;
      this.alert.text = text;
      this.alert.status = status;
      this.alert.show = true;
    },

    hidePageAlert() {
      this.alert.show = false;
    },

    async completeStep() {
      if (this.sdwLoginRequired) {
        let sdwLoginHref = "https://servicedeskweb.telia.se/SSO/MyBusinessLogin.aspx";

        if (isTestEnv()) {
          sdwLoginHref = "https://servicedeskweb-at1.teliasonera.net/SSO/MyBusinessLogin.aspx";
        }

        window.location.href = sdwLoginHref;
      } else if (this.hasSdwOrderAccess) {
        let sdwOrderHref =
          "https://servicedeskweb.telia.se/SSO/MyBusinessLogin.aspx?ReturnUrl=/Order/NewMultiOrder.aspx";

        if (isTestEnv()) {
          sdwOrderHref =
            "https://servicedeskweb-at1.teliasonera.net/SSO/MyBusinessLogin.aspx?ReturnUrl=/Order/NewMultiOrder.aspx";
        }

        window.location.href = sdwOrderHref;
      } else {
        this.$emit("step-complete");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.step-button-wrapper {
  margin-top: $telia-spacing-20;
}
</style>
