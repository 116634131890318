<template>
  <div>
    <div
      v-if="isNewOrder && isFunctionBusinessOrder && installationAddress.streetName"
      class="margin__bottom__24"
    >
      <Summary :editable="false">
        <telia-p class="margin__bottom__12">
          <strong>{{ t("DATANET_INSTALLATION_ADDRESS") }}</strong>
          <br />
          <div v-if="installationAddress.streetName">
            {{
              `${installationAddress.streetName} ${installationAddress.streetNumber}${
                installationAddress.entrance || ""
              }`
            }}
            <br />
            {{ `${installationAddress.postalCode} ${installationAddress.city}` }}
            <br />
          </div>
          <div v-else-if="installationAddress.x">
            {{ `X: ${installationAddress.x}` }}
            <br />
            {{ `Y: ${installationAddress.y}` }}
            <br />
          </div>
        </telia-p>
      </Summary>
    </div>
    <!-- Bearer -->
    <div v-if="shouldShowBearerSelection" class="margin__bottom__24">
      <InformationBox v-if="activeTooltip === 'access'" @close="setActiveTooltip(null)">
        <AccessTooltip />
      </InformationBox>
      <div class="tooltip-wrapper">
        <telia-heading tag="h4" variant="title-100" v-text="t('DATANET_ACCESS_TYPE')" />
        <telia-icon
          class="tooltip-icon"
          name="question"
          size="sm"
          @click="setActiveTooltip('access')"
        />
      </div>
      <telia-fieldset class="chip-choice-wrapper">
        <div class="chip-choice">
          <telia-chip-choice
            v-for="bearer in bearerOptions"
            :key="bearer.value"
            id="primary-access-bearer"
            name="bearer"
            caption="fieldset for bearer"
            :checked="primaryAccessBearer === bearer.value"
            :disabled="bearer.disabled"
            @change="handleBearerSelection(bearer.value)"
          >
            {{ bearer.label }}
          </telia-chip-choice>
        </div>
      </telia-fieldset>
      <!-- Apartment -->
      <template v-if="isFttxAccess">
        <div class="input-wrapper">
          <telia-select
            id="apartment-number"
            :label="t('DATANET_APARTMENT_NUMBER')"
            :options="JSON.stringify(apartmentOptions)"
            @change="handleApartmentSelection($event.target.value)"
          />
          <ValidationError v-if="$v.apartment.$error">
            {{ t("DATANET_APARTMENT_NUMBER_REQUIRED") }}
          </ValidationError>
        </div>

        <!-- Extra installation info -->
        <div class="input-wrapper">
          <telia-text-input
            id="installation-room"
            :label="t('DATANET_INSTALLATION_ROOM')"
            :value="installationRoom"
            @input="handleInstallationRoomInput($event.target.value)"
          />
        </div>
      </template>
    </div>

    <WirelessInstallation
      v-if="shouldShowPrimaryAccessWirelessInstallation"
      class="margin__bottom__24"
      :initial-value="wirelessInstallation"
      :disabled="shouldDisablePrimaryAccessWirelessInstallation"
      @request-installation-by-telia="handleWirelessInstallationData($event)"
    />
    <div>
      <InformationBox v-if="activeTooltip === 'sla'" @close="setActiveTooltip(null)">
        <SLATooltip class="margin-bottom" />
      </InformationBox>
      <InformationBox v-if="activeTooltip === 'qos'" @close="setActiveTooltip(null)">
        <QOSTooltip />
      </InformationBox>

      <telia-heading
        tag="h3"
        variant="title-200"
        class="margin__bottom__12"
        v-text="t('DATANET_ACCESS_BASE_SETTINGS')"
      />
      <!-- Bandwidth -->
      <div class="input-wrapper">
        <telia-select
          v-if="shouldShowPrimaryAccessBandwidth"
          t-id="primary-access-bandwidth"
          :label="t('DATANET_SPEED')"
          :disabled="shouldDisablePrimaryAccessBandwidth"
          :options="JSON.stringify(bandwidthOptions)"
          @change="handleBandwidthSelection($event.target.value)"
        />

        <ValidationError v-if="$v.bandwidth.$error">
          {{ t("DATANET_SPEED_REQUIRED") }}
        </ValidationError>
      </div>

      <!-- SLA -->
      <div class="input-with-tooltip">
        <div class="input-wrapper">
          <telia-select
            v-if="shouldShowPrimaryAccessSla"
            t-id="primary-access-sla"
            :label="t('DATANET_SLA')"
            :disabled="shouldDisablePrimaryAccessSla"
            :options="JSON.stringify(slaOptions)"
            @change="handleSlaSelection($event.target.value)"
          />
          <ValidationError v-if="$v.sla.$error">
            {{ t("DATANET_SLA_REQUIRED") }}
          </ValidationError>
        </div>
        <telia-icon
          class="tooltip-icon-inputs"
          name="question"
          size="sm"
          @click="setActiveTooltip('sla')"
        />
      </div>

      <!-- QoS -->
      <div class="input-with-tooltip">
        <div class="input-wrapper">
          <telia-select
            v-if="shouldShowPrimaryAccessQos"
            t-id="primary-access-qos"
            :label="t('DATANET_QOS')"
            :disabled="shouldDisablePrimaryAccessQos"
            :options="JSON.stringify(qosOptions)"
            @change="handleQosSelection($event.target.value)"
          />
          <ValidationError v-if="$v.qos.$error">
            {{ t("DATANET_QOS_REQUIRED") }}
          </ValidationError>
        </div>
        <telia-icon
          class="tooltip-icon-inputs"
          name="question"
          size="sm"
          @click="setActiveTooltip('qos')"
        />
      </div>
    </div>
    <StatBas
      v-if="shouldShowPrimaryAccessTrafficStatistics"
      class="component-wrapper"
      access-key="primary"
      :initial-value="statBas"
      @request-stat-bas="handleStatBasSelection($event)"
    />
    <QuickLaunch v-if="shouldShowQuickLaunch" class="component-wrapper" />

    <div v-if="shouldShowExtendedAvailability" class="component-wrapper">
      <ExtendedAvailability />
      <ValidationError
        v-if="$v.secondaryAccess.$error"
        v-text="t('DATANET_SECONDARY_ACCESS_REQUIRED')"
      />
    </div>

    <div v-if="shouldShowAccessOptions">
      <InformationBox v-if="activeTooltip === 'snmp'" @close="setActiveTooltip(null)">
        <SNMPTooltip />
      </InformationBox>
      <telia-heading
        tag="h3"
        variant="title-200"
        class="margin__bottom__12"
        v-text="t('DATANET_ADVANCED_SETTINGS')"
      />
      <div v-show="isExtraAccessOptionsToggled">
        <div v-if="shouldShowPrimaryAccessSnmp">
          <div class="tooltip-wrapper margin__bottom__12">
            <telia-heading tag="h4" variant="title-100">
              {{ t("DATANET_SNMP") }}
            </telia-heading>
            <telia-icon
              class="tooltip-icon"
              name="question"
              size="sm"
              @click="setActiveTooltip('snmp')"
            />
          </div>
          <Snmp class="component-wrapper" ref="snmp" />
          <div>
            <ValidationError
              v-if="$v.snmp.communities.$invalid"
              v-text="t('DATANET_SNMP_IS_MANDATORY')"
            />
          </div>

          <div v-if="shouldShowPrimaryAccessInternetOnSite">
            <div class="tooltip-wrapper margin__bottom__12">
              <telia-heading tag="h4" variant="title-100">
                {{ t("DATANET_INTERNET_ON_SITE") }}
              </telia-heading>
            </div>
            <InternetOnSite class="component-wrapper" ref="internet-on-site" />
          </div>
        </div>
        <AntennaPackage
          class="component-wrapper"
          v-if="shouldShowPrimaryAccessAntennaPackage"
          access-key="primary"
          @antenna-package-data="handleAntennaPackageData($event)"
        />
      </div>
      <!-- TODO: Toggle seems unnecessary while we only have so few add-ons. We can enable it if we add support for more.
      <div class="button-wrapper">
        <telia-button
          t-id="toggle-extra-access-options"
          type="button"
          variant="secondary"
          size="sm"
          @click="toggleExtraAccessOptions()"
        >
          {{
            isExtraAccessOptionsToggled
              ? t("DATANET_HIDE_ADVANCED_SETTINGS")
              : t("DATANET_SHOW_ADVANCED_SETTINGS")
          }}
        </telia-button>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import InformationBox from "../InformationBox";
import AccessTooltip from "../tooltip-information/access-settings/AccessTooltip.vue";
import SLATooltip from "../tooltip-information/access-settings/SLATooltip";
import QOSTooltip from "../tooltip-information/access-settings/QOSTooltip.vue";
import SNMPTooltip from "../tooltip-information/access-settings/SNMPTooltip.vue";
import QuickLaunch from "./QuickLaunch";
import ExtendedAvailability from "./ExtendedAvailability";
import Snmp from "./Snmp";
import StatBas from "./StatBas";
import AntennaPackage from "./AntennaPackage";
import WirelessInstallation from "./WirelessInstallation";
import ValidationError from "../ValidationError";
import Summary from "../Summary.vue";
import InternetOnSite from "./InternetOnSite.vue";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { mapState, mapGetters, mapActions } from "vuex";
import { Accesses, Bearers, MetadataKeys } from "../../constants";
import getIndexOfValueInDropdownOptions from "../../helpers/getIndexOfValueInDropdownOptions.js";
import { translateMixin } from "../../locale";

export default {
  name: "AccessSettings",
  mixins: [translateMixin, validationMixin],
  components: {
    InternetOnSite,
    Summary,
    InformationBox,
    SLATooltip,
    QuickLaunch,
    ExtendedAvailability,
    Snmp,
    StatBas,
    AntennaPackage,
    WirelessInstallation,
    ValidationError,
    QOSTooltip,
    SNMPTooltip,
    AccessTooltip,
  },

  data() {
    return {
      Bearers,
      activeTooltip: null,
    };
  },

  computed: {
    ...mapState({
      isExtraAccessOptionsToggled: (state) => state.showAdvancedSettings,
    }),

    ...mapState("access", {
      wirelessInstallation: (state) => state[Accesses.PRIMARY].options.wirelessInstallation,
      antennaCable: (state) => state[Accesses.PRIMARY].options.antenna.cable,
      antennaInstallation: (state) => state[Accesses.PRIMARY].options.antenna.installation,
      antennaPackage: (state) => state[Accesses.PRIMARY].options.antenna.package,
      bandwidth: (state) => state[Accesses.PRIMARY].bandwidth,
      qos: (state) => state[Accesses.PRIMARY].qos,
      secondaryAccessEnabled: (state) => state[Accesses.SECONDARY].enable,
      sla: (state) => state[Accesses.PRIMARY].sla,
      snmp: (state) => state[Accesses.PRIMARY].options.snmp,
      statBas: (state) => state[Accesses.PRIMARY].options.trafficStatistics.new,
      useTempAccessAsSecondary: (state) => state[Accesses.TEMPORARY].useAsSecondaryAccess,
    }),

    ...mapState("addresses", {
      installationRoom: (state) => state.installation.installationRoom,
    }),

    ...mapState("apartments", {
      apartment: (state) => state.apartment,
    }),

    ...mapState("products", {
      primaryAccessBearer: (state) => state.bearer.value,
    }),

    ...mapGetters("access", [
      "shouldDisablePrimaryAccessBandwidth",
      "shouldDisablePrimaryAccessQos",
      "shouldDisablePrimaryAccessSla",
      "shouldDisablePrimaryAccessWirelessInstallation",
      "shouldShowAccessOptions",
      "shouldShowPrimaryAccessAntennaPackage",
      "shouldShowPrimaryAccessApartment",
      "shouldShowPrimaryAccessBandwidth",
      "shouldShowPrimaryAccessQos",
      "shouldShowPrimaryAccessSla",
      "shouldShowPrimaryAccessSnmp",
      "shouldShowPrimaryAccessInternetOnSite",
      "shouldShowPrimaryAccessTrafficStatistics",
      "shouldShowPrimaryAccessWirelessInstallation",
      "shouldValidatePrimaryAccessAntennaPackage",
      "shouldValidatePrimaryAccessApartment",
      "shouldValidatePrimaryAccessBandwidth",
      "shouldValidatePrimaryAccessQos",
      "shouldValidatePrimaryAccessSla",
      "shouldValidatePrimaryAccessSnmp",
    ]),

    ...mapGetters("apartments", {
      apartmentOptions: "getApartmentOptions",
    }),

    ...mapGetters("contactPersons", ["hasOrderContact"]),

    ...mapGetters("products", [
      "getBearer",
      "getBearerOptions",
      "getBandwidthOptions",
      "getDefaultSlaForAccess",
      "getQosOptions",
      "getSlaOptions",
      "hasExtendedAvailabilityAvailable",
      "hasFastTrackAvailable",
      "hasQuickLaunchAvailable",
      "isCopperAccess",
      "isFiberAccess",
      "isFiberAvailable",
      "isFttxAccess",
      "isFttxAvailable",
      "isWirelessAccess",
      "shouldShowBearerSelection",
      "shouldShowExtendedAvailability",
      "shouldShowQuickLaunch",
    ]),

    ...mapGetters(["isNewOrder", "isChangeOrder", "isFunctionBusinessOrder"]),

    ...mapGetters("metadata", ["isMandatory"]),

    ...mapGetters("addresses", ["getAddress"]),

    installationAddress() {
      return this.getAddress("installation");
    },

    defaultApartment() {
      return getIndexOfValueInDropdownOptions(this.apartment, this.apartmentOptions);
    },

    bearerOptions() {
      return this.getBearerOptions(Accesses.PRIMARY);
    },

    bandwidthOptions() {
      return this.getBandwidthOptions(this.primaryAccessBearer).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.bandwidth.new,
          disabled: option.disabled,
        };
      });
    },

    slaOptions() {
      return this.getSlaOptions(this.primaryAccessBearer).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.sla.new,
          disabled: option.disabled,
        };
      });
    },

    defaultSla() {
      return getIndexOfValueInDropdownOptions(this.sla.new, this.slaOptions);
    },

    qosOptions() {
      return this.getQosOptions(this.primaryAccessBearer, this.bandwidth.new).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.qos.new,
          disabled: option.disabled,
        };
      });
    },
  },

  validations: {
    apartment: {
      required: requiredIf(function () {
        return this.shouldValidatePrimaryAccessApartment;
      }),
    },
    bandwidth: {
      new: {
        required: requiredIf(function () {
          return this.shouldValidatePrimaryAccessBandwidth;
        }),
      },
    },
    sla: {
      new: {
        required: requiredIf(function () {
          return this.shouldValidatePrimaryAccessSla;
        }),
      },
    },
    qos: {
      new: {
        required: requiredIf(function () {
          return this.shouldValidatePrimaryAccessQos;
        }),
      },
    },
    secondaryAccess: {
      exists: function () {
        return this.useTempAccessAsSecondary ? this.secondaryAccessEnabled : true;
      },
    },
    snmp: {
      communities: {
        required: requiredIf(function () {
          return this.isMandatory(MetadataKeys.SNMP);
        }),
      },
    },
  },

  mounted() {
    if (this.shouldSetDefaultSla()) {
      this.setSla({
        accessKey: Accesses.PRIMARY,
        new: this.getDefaultSlaForAccess(this.sla.new),
      });
    }
  },

  methods: {
    ...mapActions("access", [
      "setTrafficStatistics",
      "enableWirelessInstallation",
      "disableWirelessInstallation",
      "setAntennaCable",
      "setAntennaInstallation",
      "setAntennaPackage",
      "setBandwidth",
      "setQos",
      "setSla",
    ]),

    ...mapActions("addresses", ["setInstallationRoomInfo"]),

    ...mapActions("apartments", ["setApartment"]),

    ...mapActions("products", ["setBearer"]),

    ...mapActions(["hideExtraAccessOptions", "showExtraAccessOptions"]),

    shouldSetDefaultSla() {
      return this.shouldShowPrimaryAccessSla && !this.sla.new;
    },

    async completeStep() {
      this.$v.$touch();

      if (this.$v.snmp.communities.$invalid) {
        this.toggleExtraAccessOptions();
      }

      if (!this.$v.$invalid) {
        this.$emit("step-complete");
      }
    },

    handleBearerSelection(value) {
      this.setBearer({ value });
    },

    handleApartmentSelection(value) {
      this.setApartment(value);
    },

    handleInstallationRoomInput(value) {
      this.setInstallationRoomInfo(value);
    },

    handleWirelessInstallationData(value) {
      value
        ? this.enableWirelessInstallation(Accesses.PRIMARY)
        : this.disableWirelessInstallation(Accesses.PRIMARY);
    },

    handleAntennaPackageData(data) {
      this.setAntennaPackage({
        accessKey: Accesses.PRIMARY,
        name: data.antennaPackage,
      });
      this.setAntennaCable({
        accessKey: Accesses.PRIMARY,
        boolean: data.antennaCable,
      });
      this.setAntennaInstallation({
        accessKey: Accesses.PRIMARY,
        boolean: data.antennaInstallation,
      });
    },

    handleBandwidthSelection(value) {
      this.setBandwidth({
        accessKey: Accesses.PRIMARY,
        new: value,
      });
    },

    handleSlaSelection(value) {
      this.setSla({
        accessKey: Accesses.PRIMARY,
        new: value,
      });
    },

    handleQosSelection(value) {
      this.setQos({
        accessKey: Accesses.PRIMARY,
        new: value,
      });
    },

    handleStatBasSelection(value) {
      this.setTrafficStatistics({
        accessKey: Accesses.PRIMARY,
        new: value,
      });
    },

    toggleExtraAccessOptions() {
      this.isExtraAccessOptionsToggled
        ? this.hideExtraAccessOptions()
        : this.showExtraAccessOptions();
    },
    setActiveTooltip(value) {
      if (value === this.activeTooltip) {
        this.activeTooltip = null;
        return;
      }
      this.activeTooltip = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";
.input-wrapper {
  max-width: 42rem;
  margin-bottom: $telia-spacing-32;
  flex: 1;
}
.input-with-tooltip {
  display: flex;
}

.tooltip-icon-inputs {
  position: relative;
  top: 3.8rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon {
  position: relative;
  top: 0.5rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon:hover,
.tooltip-icon-inputs:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
.component-wrapper {
  margin-bottom: $telia-spacing-32;
}
.chip-choice-wrapper {
  display: flex;
  margin-bottom: $telia-spacing-24;
}
.button-wrapper {
  margin: $telia-spacing-12 0 $telia-spacing-24 0;
}
.chip-choice > * {
  margin-right: $telia-spacing-12;
}
.tooltip-wrapper {
  display: flex;
}
.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
  &__left {
    &__48 {
      margin-left: $telia-spacing-48;
    }
  }
}
</style>
