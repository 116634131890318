var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "telia-fieldset",
        { attrs: { legend: _vm.t("DATANET_INTERNET_ON_SITE") } },
        [
          _c("ActionSelection", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isChangeOrder,
                expression: "isChangeOrder",
              },
            ],
            attrs: {
              "disabled-actions": _vm.disabledActions,
              "selected-action": _vm.internetOnSiteAction,
            },
            on: {
              selected: function ($event) {
                return _vm.handleActionSelection($event)
              },
            },
          }),
          _c(
            "telia-p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.internetOnSiteAction === "DELETE",
                  expression: "internetOnSiteAction === 'DELETE'",
                },
              ],
              staticClass: "no-margin",
              attrs: { "disable-hyphenation": "" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_WILL_BE_REMOVED")) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.internetOnSiteAction === "ADD",
                  expression: "internetOnSiteAction === 'ADD'",
                },
              ],
              staticClass: "fieldset-wrapper",
            },
            [
              _c(
                "telia-fieldset",
                {
                  attrs: {
                    name: "ip-options",
                    caption: "fieldset for ip-options",
                    legend: _vm.t("DATANET_INTERNET_ON_SITE_IP_ADDRESSES"),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chip-choice-wrapper" },
                    [
                      _c(
                        "telia-chip-choice",
                        {
                          attrs: {
                            id: "use-existing-ip-addresses",
                            checked: _vm.useExistingIpAddresses,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleUseExistingIpsInput(true)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.t("DATANET_INTERNET_ON_SITE_EXISTING_IP")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "telia-chip-choice",
                        {
                          attrs: {
                            id: "use-new-ip-addresses",
                            checked: !_vm.useExistingIpAddresses,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleUseExistingIpsInput(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_NEW_IP")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.useExistingIpAddresses,
                  expression: "!useExistingIpAddresses",
                },
              ],
            },
            [
              _c(
                "telia-p",
                {
                  staticClass: "additional-info",
                  attrs: { "disable-hyphenation": "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_IP_REQUEST_1")) +
                      "\n        "
                  ),
                  _c(
                    "telia-link",
                    {
                      attrs: {
                        href:
                          "https://iprequest.teliacompany.com/teliaSE/index.html",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_IP_REQUEST")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_IP_REQUEST_2")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "input-wrapper" },
                [
                  _c("telia-text-input", {
                    attrs: {
                      "t-id": "ripe-number",
                      label: _vm.t("DATANET_INTERNET_ON_SITE_RIPE_NUMBER"),
                      value: _vm.ripeNumber,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleRipeNumberInput($event.target.value)
                      },
                    },
                  }),
                  _vm.$v.ripeNumber.$error
                    ? _c("ValidationError", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.t(
                                "DATANET_INTERNET_ON_SITE_RIPE_NUMBER_ERROR"
                              )
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.internetOnSiteAction === "ADD",
                  expression: "internetOnSiteAction === 'ADD'",
                },
              ],
              staticClass: "fieldset-wrapper",
            },
            [
              _c(
                "telia-fieldset",
                {
                  attrs: {
                    name: "lan-interface-options",
                    caption: "fieldset for lan-interface-options",
                    legend: _vm.t("DATANET_INTERNET_ON_SITE_INTERFACE"),
                  },
                },
                [
                  _c(
                    "telia-p",
                    {
                      staticClass: "additional-info",
                      attrs: { "disable-hyphenation": "" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.t("DATANET_INTERNET_ON_SITE_INTERFACE_INFO")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "chip-choice-wrapper" },
                    [
                      _c(
                        "telia-chip-choice",
                        {
                          attrs: {
                            id: "enable-lan-interface",
                            checked: _vm.lanInterface === "LAN",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleLanInterfaceInput("LAN")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_LAN")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "telia-chip-choice",
                        {
                          attrs: {
                            id: "enable-vlan-interface",
                            checked: _vm.lanInterface === "VLAN",
                            disabled: true,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleLanInterfaceInput("VLAN")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_VLAN")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.internetOnSiteAction === "UPDATE" &&
                    !_vm.isBandwidthChangeConfigActive,
                  expression:
                    "internetOnSiteAction === 'UPDATE' && !isBandwidthChangeConfigActive",
                },
              ],
            },
            [
              _c("telia-button", {
                attrs: {
                  "t-id": "add-bandwidth-change-config",
                  text: _vm.t(
                    "DATANET_INTERNET_ON_SITE_ADD_CHANGE_ON_BANDWIDTH"
                  ),
                  "left-icon": JSON.stringify({ name: "plus", size: "sm" }),
                  variant: "text",
                },
                on: {
                  click: function ($event) {
                    return _vm.showBandwidthChangeConfig()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldShowCapacityConfig,
                  expression: "shouldShowCapacityConfig",
                },
              ],
              staticClass: "fieldset-wrapper",
            },
            [
              _c(
                "telia-fieldset",
                {
                  attrs: {
                    name: "capacity-options",
                    caption: "fieldset for capacity-options",
                    legend: _vm.t("DATANET_INTERNET_ON_SITE_CAPACITY"),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chip-choice-wrapper" },
                    [
                      _c(
                        "telia-chip-choice",
                        {
                          attrs: {
                            id: "enable-dynamic-capacity",
                            checked: _vm.useDynamicCapacity,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleUseDynamicCapacityInput(true)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.t(
                                  "DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY"
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "telia-chip-choice",
                        {
                          attrs: {
                            id: "disable-dynamic-capacity",
                            checked: !_vm.useDynamicCapacity,
                            disabled: _vm.isWirelessAccess,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleUseDynamicCapacityInput(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.t(
                                  "DATANET_INTERNET_ON_SITE_STATIC_BANDWIDTH"
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.shouldShowCapacityConfig && !_vm.useDynamicCapacity,
                  expression: "shouldShowCapacityConfig && !useDynamicCapacity",
                },
              ],
              staticClass: "fieldset-wrapper",
            },
            [
              _c(
                "telia-fieldset",
                {
                  attrs: {
                    name: "static-bandwidth-options",
                    caption: "fieldset for static-bandwidth-options",
                    legend: _vm.t("DATANET_INTERNET_ON_SITE_SELECT_BANDWIDTH"),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-wrapper-no-padding" },
                    [
                      _c("telia-select", {
                        attrs: {
                          "t-id": "internet-on-site-bandwidth",
                          options: JSON.stringify(_vm.staticBandwidthOptions),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleStaticBandwidthSelection(
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm.$v.staticBandwidth.$error
                        ? _c("ValidationError", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.t(
                                    "DATANET_INTERNET_ON_SITE_STATIC_BANDWIDTH_ERROR"
                                  )
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.internetOnSiteAction === "UPDATE" &&
                    _vm.lanInterface === "LAN" &&
                    !_vm.isInterfaceChangeConfigActive,
                  expression:
                    "\n        internetOnSiteAction === 'UPDATE' &&\n        lanInterface === 'LAN' &&\n        !isInterfaceChangeConfigActive\n      ",
                },
              ],
            },
            [
              _c("telia-button", {
                attrs: {
                  "t-id": "add-interface-change-config",
                  text: _vm.t(
                    "DATANET_INTERNET_ON_SITE_ADD_CHANGE_ON_INTERFACE"
                  ),
                  "left-icon": JSON.stringify({ name: "plus", size: "sm" }),
                  variant: "text",
                },
                on: {
                  click: function ($event) {
                    return _vm.showInterfaceChangeConfig()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldShowEthernetInterfaceConfig,
                  expression: "shouldShowEthernetInterfaceConfig",
                },
              ],
              staticClass: "fieldset-wrapper",
            },
            [
              _c(
                "telia-fieldset",
                {
                  attrs: {
                    name: "ethernet-interface-options",
                    caption: "fieldset for capacity-options",
                    legend: _vm.t(
                      "DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES"
                    ),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-wrapper-no-padding" },
                    [
                      _c("telia-select", {
                        attrs: {
                          "t-id": "internet-on-site-ethernet-interfaces",
                          options: JSON.stringify(_vm.ethernetInterfaceOptions),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleExtraEthernetInterfaceSelection(
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-wrapper" },
        [
          _c(
            "telia-button",
            {
              attrs: {
                id: "save-internet-on-site-configuration",
                "t-id": "save-internet-on-site-configuration",
                variant: "expressive",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.saveAndClose()
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.t("DATANET_SAVE_CHANGES")) + "\n    "
              ),
            ]
          ),
          _c(
            "telia-button",
            {
              attrs: {
                id: "cancel-internet-on-site-configuration",
                "t-id": "cancel-internet-on-site-configuration",
                type: "button",
                variant: "secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("complete")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.t("DATANET_ABORT")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }