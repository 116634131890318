var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isNewOrder &&
      _vm.isFunctionBusinessOrder &&
      _vm.installationAddress.streetName
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _c(
                "Summary",
                { attrs: { editable: false } },
                [
                  _c("telia-p", { staticClass: "margin__bottom__12" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.t("DATANET_INSTALLATION_ADDRESS"))),
                    ]),
                    _c("br"),
                    _vm.installationAddress.streetName
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.installationAddress.streetName +
                                  " " +
                                  _vm.installationAddress.streetNumber +
                                  (_vm.installationAddress.entrance || "")
                              ) +
                              "\n          "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.installationAddress.postalCode +
                                  " " +
                                  _vm.installationAddress.city
                              ) +
                              "\n          "
                          ),
                          _c("br"),
                        ])
                      : _vm.installationAddress.x
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s("X: " + _vm.installationAddress.x) +
                              "\n          "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s("Y: " + _vm.installationAddress.y) +
                              "\n          "
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldShowBearerSelection
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _vm.activeTooltip === "access"
                ? _c(
                    "InformationBox",
                    {
                      on: {
                        close: function ($event) {
                          return _vm.setActiveTooltip(null)
                        },
                      },
                    },
                    [_c("AccessTooltip")],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "tooltip-wrapper" },
                [
                  _c("telia-heading", {
                    attrs: { tag: "h4", variant: "title-100" },
                    domProps: {
                      textContent: _vm._s(_vm.t("DATANET_ACCESS_TYPE")),
                    },
                  }),
                  _c("telia-icon", {
                    staticClass: "tooltip-icon",
                    attrs: { name: "question", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveTooltip("access")
                      },
                    },
                  }),
                ],
                1
              ),
              _c("telia-fieldset", { staticClass: "chip-choice-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "chip-choice" },
                  _vm._l(_vm.bearerOptions, function (bearer) {
                    return _c(
                      "telia-chip-choice",
                      {
                        key: bearer.value,
                        attrs: {
                          id: "primary-access-bearer",
                          name: "bearer",
                          caption: "fieldset for bearer",
                          checked: _vm.primaryAccessBearer === bearer.value,
                          disabled: bearer.disabled,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleBearerSelection(bearer.value)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(bearer.label) + "\n        "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]),
              _vm.isFttxAccess
                ? [
                    _c(
                      "div",
                      { staticClass: "input-wrapper" },
                      [
                        _c("telia-select", {
                          attrs: {
                            id: "apartment-number",
                            label: _vm.t("DATANET_APARTMENT_NUMBER"),
                            options: JSON.stringify(_vm.apartmentOptions),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleApartmentSelection(
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm.$v.apartment.$error
                          ? _c("ValidationError", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.t("DATANET_APARTMENT_NUMBER_REQUIRED")
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "input-wrapper" },
                      [
                        _c("telia-text-input", {
                          attrs: {
                            id: "installation-room",
                            label: _vm.t("DATANET_INSTALLATION_ROOM"),
                            value: _vm.installationRoom,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleInstallationRoomInput(
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.shouldShowPrimaryAccessWirelessInstallation
        ? _c("WirelessInstallation", {
            staticClass: "margin__bottom__24",
            attrs: {
              "initial-value": _vm.wirelessInstallation,
              disabled: _vm.shouldDisablePrimaryAccessWirelessInstallation,
            },
            on: {
              "request-installation-by-telia": function ($event) {
                return _vm.handleWirelessInstallationData($event)
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        [
          _vm.activeTooltip === "sla"
            ? _c(
                "InformationBox",
                {
                  on: {
                    close: function ($event) {
                      return _vm.setActiveTooltip(null)
                    },
                  },
                },
                [_c("SLATooltip", { staticClass: "margin-bottom" })],
                1
              )
            : _vm._e(),
          _vm.activeTooltip === "qos"
            ? _c(
                "InformationBox",
                {
                  on: {
                    close: function ($event) {
                      return _vm.setActiveTooltip(null)
                    },
                  },
                },
                [_c("QOSTooltip")],
                1
              )
            : _vm._e(),
          _c("telia-heading", {
            staticClass: "margin__bottom__12",
            attrs: { tag: "h3", variant: "title-200" },
            domProps: {
              textContent: _vm._s(_vm.t("DATANET_ACCESS_BASE_SETTINGS")),
            },
          }),
          _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _vm.shouldShowPrimaryAccessBandwidth
                ? _c("telia-select", {
                    attrs: {
                      "t-id": "primary-access-bandwidth",
                      label: _vm.t("DATANET_SPEED"),
                      disabled: _vm.shouldDisablePrimaryAccessBandwidth,
                      options: JSON.stringify(_vm.bandwidthOptions),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleBandwidthSelection($event.target.value)
                      },
                    },
                  })
                : _vm._e(),
              _vm.$v.bandwidth.$error
                ? _c("ValidationError", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("DATANET_SPEED_REQUIRED")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input-with-tooltip" },
            [
              _c(
                "div",
                { staticClass: "input-wrapper" },
                [
                  _vm.shouldShowPrimaryAccessSla
                    ? _c("telia-select", {
                        attrs: {
                          "t-id": "primary-access-sla",
                          label: _vm.t("DATANET_SLA"),
                          disabled: _vm.shouldDisablePrimaryAccessSla,
                          options: JSON.stringify(_vm.slaOptions),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleSlaSelection($event.target.value)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.$v.sla.$error
                    ? _c("ValidationError", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.t("DATANET_SLA_REQUIRED")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("telia-icon", {
                staticClass: "tooltip-icon-inputs",
                attrs: { name: "question", size: "sm" },
                on: {
                  click: function ($event) {
                    return _vm.setActiveTooltip("sla")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input-with-tooltip" },
            [
              _c(
                "div",
                { staticClass: "input-wrapper" },
                [
                  _vm.shouldShowPrimaryAccessQos
                    ? _c("telia-select", {
                        attrs: {
                          "t-id": "primary-access-qos",
                          label: _vm.t("DATANET_QOS"),
                          disabled: _vm.shouldDisablePrimaryAccessQos,
                          options: JSON.stringify(_vm.qosOptions),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleQosSelection($event.target.value)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.$v.qos.$error
                    ? _c("ValidationError", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.t("DATANET_QOS_REQUIRED")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("telia-icon", {
                staticClass: "tooltip-icon-inputs",
                attrs: { name: "question", size: "sm" },
                on: {
                  click: function ($event) {
                    return _vm.setActiveTooltip("qos")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.shouldShowPrimaryAccessTrafficStatistics
        ? _c("StatBas", {
            staticClass: "component-wrapper",
            attrs: { "access-key": "primary", "initial-value": _vm.statBas },
            on: {
              "request-stat-bas": function ($event) {
                return _vm.handleStatBasSelection($event)
              },
            },
          })
        : _vm._e(),
      _vm.shouldShowQuickLaunch
        ? _c("QuickLaunch", { staticClass: "component-wrapper" })
        : _vm._e(),
      _vm.shouldShowExtendedAvailability
        ? _c(
            "div",
            { staticClass: "component-wrapper" },
            [
              _c("ExtendedAvailability"),
              _vm.$v.secondaryAccess.$error
                ? _c("ValidationError", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.t("DATANET_SECONDARY_ACCESS_REQUIRED")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldShowAccessOptions
        ? _c(
            "div",
            [
              _vm.activeTooltip === "snmp"
                ? _c(
                    "InformationBox",
                    {
                      on: {
                        close: function ($event) {
                          return _vm.setActiveTooltip(null)
                        },
                      },
                    },
                    [_c("SNMPTooltip")],
                    1
                  )
                : _vm._e(),
              _c("telia-heading", {
                staticClass: "margin__bottom__12",
                attrs: { tag: "h3", variant: "title-200" },
                domProps: {
                  textContent: _vm._s(_vm.t("DATANET_ADVANCED_SETTINGS")),
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isExtraAccessOptionsToggled,
                      expression: "isExtraAccessOptionsToggled",
                    },
                  ],
                },
                [
                  _vm.shouldShowPrimaryAccessSnmp
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tooltip-wrapper margin__bottom__12",
                            },
                            [
                              _c(
                                "telia-heading",
                                { attrs: { tag: "h4", variant: "title-100" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.t("DATANET_SNMP")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _c("telia-icon", {
                                staticClass: "tooltip-icon",
                                attrs: { name: "question", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setActiveTooltip("snmp")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("Snmp", {
                            ref: "snmp",
                            staticClass: "component-wrapper",
                          }),
                          _c(
                            "div",
                            [
                              _vm.$v.snmp.communities.$invalid
                                ? _c("ValidationError", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.t("DATANET_SNMP_IS_MANDATORY")
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.shouldShowPrimaryAccessInternetOnSite
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tooltip-wrapper margin__bottom__12",
                                    },
                                    [
                                      _c(
                                        "telia-heading",
                                        {
                                          attrs: {
                                            tag: "h4",
                                            variant: "title-100",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.t(
                                                  "DATANET_INTERNET_ON_SITE"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("InternetOnSite", {
                                    ref: "internet-on-site",
                                    staticClass: "component-wrapper",
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.shouldShowPrimaryAccessAntennaPackage
                    ? _c("AntennaPackage", {
                        staticClass: "component-wrapper",
                        attrs: { "access-key": "primary" },
                        on: {
                          "antenna-package-data": function ($event) {
                            return _vm.handleAntennaPackageData($event)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }