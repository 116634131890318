<template>
  <div class="save-bar-container">
    <telia-grid>
      <telia-row>
        <telia-col width="4">
          <telia-heading tag="h3" variant="title-200">
            {{ t("DATANET_SAVE_ORDER") }}
          </telia-heading>
          <telia-p>
            {{ t("DATANET_SAVE_ORDER_DESC") }}
          </telia-p>
        </telia-col>
        <telia-col width="8">
          <div class="action-container">
            <div
              v-if="hasOrderSharingEnabled || isSharedOrder"
              class="share-order-toggle-container"
            >
              <div
                class="shared-order"
                @mouseover="showTooltip = true"
                @mouseleave="showTooltip = false"
              >
                <Tooltip :show="showTooltip">
                  <template>
                    <telia-p>
                      {{
                        isOrderCreatedByCurrentUser
                          ? t("DATANET_SHARE_ORDER_TOOLTIP")
                          : t("DATANET_NOT_ALLOWED_TO_TOGGLE")
                      }}
                    </telia-p>
                  </template>
                </Tooltip>
                <telia-toggle
                  id="toggle-share-order"
                  :label="t('DATANET_SHARE_ORDER')"
                  label-position="before"
                  :disabled="!isOrderCreatedByCurrentUser ? 'true' : 'false'"
                  :selected="isSharedOrder"
                  @vocaChange="setShared($event.detail.value)"
                />
              </div>
            </div>
            <div class="save-button-container">
              <telia-button
                id="save-order"
                type="button"
                size="md"
                :disabled="isSaving || isTeliaAdmin"
                variant="expressive"
                @click="$emit('save')"
              >
                {{ isSaving ? t("DATANET_SAVING_ORDER") : t("DATANET_SAVE_ORDER") }}
              </telia-button>
              <telia-p>
                <small>
                  <em>{{ saveText }}</em>
                </small>
              </telia-p>
            </div>
            <div class="help-button-container">
              <telia-button
                id="save-order"
                type="button"
                size="sm"
                variant="secondary"
                :left-icon="JSON.stringify({ name: 'customer-dialogue', size: 'md' })"
                :text="t('HELP.BUTTON')"
                @click="showHelpModal"
              />
            </div>
          </div>
        </telia-col>
      </telia-row>
    </telia-grid>

    <Modal
      modal-id="help-modal"
      width="m"
      :is-open="isHelpModalActive"
      @closeModal="hideHelpModal()"
    >
      <div slot="modal-body">
        <div class="modal-heading-container">
          <telia-heading tag="h3" variant="title-200" class="modal-heading">
            {{ t("HELP.MODAL_TITLE") }}
          </telia-heading>
          <telia-p disable-hyphenation>
            {{ t("HELP.MODAL_ADDITIONAL") }}
          </telia-p>
        </div>
        <telia-textarea
          class="help-text-area"
          :label="t('HELP.HELP_INPUT_TITLE')"
          :additional="t('HELP.HELP_INPUT_ADDITIONAL')"
          minlength="1"
          maxlength="4000"
          :disabled="submitHelpSuccess"
          :value="helpTextInput"
          @input="helpTextInput = $event.target.value"
        />
        <div class="help-text-notification-container">
          <telia-notification
            v-show="submitHelpSuccess"
            heading-tag="h4"
            variant="inline"
            status="success"
            html-aria-live="polite"
            html-role="alert"
          >
            <span slot="heading">
              <telia-visually-hidden>
                {{ t("A11Y_STATUS_INFORMATION") }}
              </telia-visually-hidden>
              {{ t("HELP.SUBMIT_SUCCESS_TITLE") }}
            </span>
            <span slot="content">
              <telia-p disable-hyphenation>
                {{ t("HELP.SUBMIT_SUCCESS_ADDITIONAL") }}
                <telia-link
                  v-if="tnowId"
                  variant="text"
                  :href="`/foretag/mybusiness/${scopeId}/support/arenden/detaljer/${tnowId}`"
                  target="_blank"
                >
                  {{ tnowId }}
                </telia-link>
              </telia-p>
            </span>
          </telia-notification>
          <telia-notification
            v-show="submitHelpError"
            heading-tag="h4"
            variant="inline"
            status="warning"
            html-aria-live="assertive"
            html-role="alert"
          >
            <span slot="heading">
              <telia-visually-hidden>
                {{ t("A11Y_STATUS_WARNING") }}
              </telia-visually-hidden>
              {{ t("HELP.SUBMIT_ERROR_TITLE") }}
            </span>
            <span slot="content">
              <telia-p disable-hyphenation>
                {{ t("HELP.SUBMIT_ERROR_ADDITIONAL") }}
              </telia-p>
            </span>
          </telia-notification>
        </div>
      </div>
      <div slot="button-footer">
        <div class="modal-footer-container">
          <telia-button
            type="button"
            size="sm"
            variant="expressive"
            class="submit-help-button"
            :disabled="isTeliaAdmin || !helpTextInput || isSubmittingHelp || submitHelpSuccess"
            @click="submitHelp"
          >
            {{ t("HELP.SEND") }}
          </telia-button>
          <telia-button
            type="button"
            size="sm"
            variant="secondary"
            :disabled="isSubmittingHelp"
            @click="hideHelpModal"
          >
            {{ t("HELP.CLOSE") }}
          </telia-button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { corpCompanySettingsDatanet, corpDatanetService } from "@telia/b2b-rest-client";
import { translateMixin } from "../locale";
import Modal from "./Modal";
import Tooltip from "./Tooltip";

export default {
  name: "FloatingSaveBar",
  mixins: [translateMixin],
  components: {
    Modal,
    Tooltip,
  },

  props: {
    isSaving: {
      type: Boolean,
      required: true,
    },
    hasChanges: {
      type: Boolean,
      required: true,
    },
    savedAt: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      hasOrderSharingEnabled: false,
      showTooltip: false,
      isHelpModalActive: false,
      isSubmittingHelp: false,
      submitHelpSuccess: false,
      submitHelpError: false,
      helpTextInput: "",
      tnowId: "",
    };
  },

  computed: {
    ...mapState({
      isSharedOrder: (state) => state.isShared,
      businessArea: (state) => state.businessArea,
      orderCaptureId: (state) => state.orderCaptureId,
    }),

    ...mapState("user", {
      scopeId: (state) => state.scopeId,
      isTeliaAdmin: (state) => state.isTeliaAdmin,
      firstName: (state) => state.firstName,
      lastName: (state) => state.lastName,
      email: (state) => state.email,
      phone: (state) => state.phone,
      orgNr: (state) => state.organisation,
    }),

    ...mapGetters("user", ["getOrganisation"]),

    isOrderCreatedByCurrentUser() {
      return this.$store.state.user.userId === this.$store.state.createdBy;
    },

    saveText: function () {
      if (this.hasChanges) {
        return this.t("DATANET_UNSAVED_CHANGES_EXISTS");
      } else if (this.savedAt) {
        return `${this.t("DATANET_SAVED_AT")} ${this.savedAt}.`;
      } else {
        return this.t("DATANET_ORDER_NOT_SAVED");
      }
    },
  },

  async mounted() {
    try {
      const sharedOrdersSetting = await corpCompanySettingsDatanet.ExternalSharedOrdersControllerService.getDataNetSharedOrdersSettingUsingGet(
        this.scopeId
      );
      this.hasOrderSharingEnabled = sharedOrdersSetting.allowSharedOrders;
    } catch (e) {
      console.error(e);
    }

    if (this.hasOrderSharingEnabled && this.$store.getters.isUnsavedOrder) {
      this.$store.dispatch("setSharedOrder");
    }
  },

  methods: {
    setShared(value) {
      if (value) {
        this.$store.dispatch("setSharedOrder");
      } else {
        this.$store.dispatch("setUnsharedOrder");
      }
    },
    showHelpModal() {
      this.isHelpModalActive = true;
    },
    hideHelpModal() {
      this.isHelpModalActive = false;
    },
    async submitHelp() {
      try {
        this.isSubmittingHelp = true;
        this.submitHelpSuccess = false;
        this.submitHelpError = false;

        // Save order automatically if it's unsaved before creating errand
        if (!this.orderCaptureId) {
          this.$emit("save");
          // Wait for save to complete or fail and then continue
          await new Promise((resolve) => setTimeout(() => resolve()));
          while (this.isSaving) {
            await new Promise((resolve) => setTimeout(() => resolve()));
          }
        }

        const organisation = this.getOrganisation(this.orgNr);
        const payload = {
          businessArea: this.businessArea,
          tscid: organisation.tscid,
          orderCaptureId: this.orderCaptureId,
          person: {
            firstName: this.firstName,
            lastName: this.lastName,
            emailAddress: this.email,
            telephoneNumber: this.phone,
          },
          description: this.helpTextInput,
        };

        const response = await corpDatanetService.ExternalDatanetControllerService.createTeliaNowTicket(
          this.scopeId,
          payload
        );

        if (response?.tnowId) {
          this.tnowId = response.tnowId;
          this.submitHelpSuccess = true;
        }

        this.isSubmittingHelp = false;
      } catch (e) {
        this.submitHelpError = true;
        this.isSubmittingHelp = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.save-bar-container {
  position: sticky;
  bottom: 0;
  background: $telia-white;
  border-top: 0.2rem $telia-gray-50 solid;
  padding: $telia-spacing-24 0;
  z-index: 100;
}

.action-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: end;
}

.share-order-toggle-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.save-button-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  max-width: 24rem;
  margin: 0 0 0 $telia-spacing-32;
}

.help-button-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 0 0 $telia-spacing-24;
}

.modal-heading-container {
  padding-bottom: $telia-spacing-24;
}

.modal-heading {
  padding-bottom: $telia-spacing-8;
}

.modal-footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  padding: 0 $telia-spacing-32 $telia-spacing-24 $telia-spacing-32;
}

.submit-help-button {
  padding-right: $telia-spacing-12;
}

::v-deep .telia-textarea__textarea {
  height: 24rem;
  resize: none;
}

.help-text-notification-container {
  padding-top: $telia-spacing-24;
}
</style>
